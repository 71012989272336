let background_state = 0;
let t = [];
let started_frame;
let transition_frames;

// const color_palette = ["#AA1200", "#433E0E", "#0A8754"];
const color_palette = ["#041118", "#456176", "#857362"];
const TETRA_COUNT = 22;
const transition_time = 0.2; // sec

function setup() {
	let canvas = createCanvas(windowWidth, windowHeight, WEBGL);
	canvas.position(0, 0);
	canvas.style("z-index", "-1");
	camera(0, 400, 200, 0, -400, 0, 0, 1, 0);
	ortho(-width / 2, width / 2, -height / 2, height / 2);
	while (t.length < TETRA_COUNT) {
		temp_new = new tetra(
			random(-width/2., width/2.),
			0,
			random(-height/2., height/2.),
			random(height/15., height/10.),
			color_palette
		);
		let too_close = false;
		for (let j = 0; j < t.length; j++) {
			if (temp_new.pos.dist(t[j].pos) < temp_new.s + t[j].s) {
				too_close = true;
				break;
			}
		}
		if (!too_close) {
			temp_new.display();
			t.push(temp_new);
		}
	}
	frameRate(60);
}


function windowResized() {
	resizeCanvas(windowWidth, windowHeight);
	// cause severe lag
	camera(0, 400, 200, 0, -400, 0, 0, 1, 0);
	ortho(-width / 2, width / 2, -height / 2, height / 2);
}

function draw() {
	// background(220);
	drawBackground();
	for (let i = 0; i < TETRA_COUNT; i++) {
		t[i].update();
		t[i].display();
	}
	// xyz();
}

function is_touch_enabled() {
	return ( 'ontouchstart' in window ) ||
		   ( navigator.maxTouchPoints > 0 ) ||
		   ( navigator.msMaxTouchPoints > 0 );
}

function xyz() {
	push();
	stroke("#FF0000");
	line(0, 0, 0, width, 0, 0);
	stroke("#00FF00");
	line(0, 0, 0, 0, height, 0);
	stroke("#0000FF");
	line(0, 0, 0, 0, 0, width);
	pop();
}

function drawBackground() {
	if (background_state == 0) {
		background('#2B3239');
	} else {
		push();
		colorMode(HSB);
		c = color_palette[background_state - 1];
		let frame_passed = frameCount - started_frame;
		let pct = min(frame_passed / transition_frames, 1.0);
		c_modify = color(hue(c), saturation(c) * 0.3, brightness(c)*pct);
		background(c_modify);
		pop();
	}
}

function changeState(to_state) {
	// stay on the same state if on touch device
	if (is_touch_enabled()) {
		return;
	}
	background_state = to_state;
	started_frame = frameCount;
	transition_frames = transition_time*frameRate()
	for (let i = 0; i < TETRA_COUNT; i++) {
		t[i].change_state(to_state);
	}
}
